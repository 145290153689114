<!--
 * @Description:人行道停车运营管理 运营管理 缴费记录 parkPayRecord
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-07-02 15:20:43
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <!--上半部查询-->
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="停车场">
              <el-select class="search-input"
                         clearable
                         v-model="searchForm.parkId"
                         placeholder="全部">
                <el-option v-for="(item, index) in parkNameList"
                           :key="index"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="统计日期">
              <el-date-picker v-model="time"
                              type="datetimerange"
                              align="right"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              unlink-panels
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :default-time="['00:00:00', '23:59:59']"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="topButton">
        <el-button type="primary"
                   @click="queryButton">查 询</el-button>
        <el-button type="info"
                   style="margin-left:17px;"
                   @click="resetForm">重 置</el-button>
      </el-row>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>缴费记录</span>
        <el-button type="info"
                   class="tableTitleButton"
                   @click="exportFile">导出</el-button>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :paginationConfig='paginationConfig'
                  :tableConfig='tableConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button @click="handleClickDetails(scope.row)"
                             type="text"
                             size="medium">详情</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
import { timeDuration } from '@/utils/parkStopTime.js'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
  },
  data () {
    //这里存放数据
    return {
      //表格
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableConfig: { border: true },
      listMap: { pageNum: 1, pageSize: 50 },
      tableColumnList: [
        {
          prop: 'parkName',
          label: '停车场名称',
        },
        {
          prop: 'totalReceivableMoneyAmount',
          label: '应收总额',
        },
        {
          prop: 'totalReceivedMoneyAmount',
          label: '实收总额',
        },
        {
          prop: 'totalRepayMoneyAmount',
          label: '补缴总额',
        },
        {
          prop: 'totalArrangeMoneyAmount',
          label: '欠费总额',
        },
        {
          prop: 'totalDiscountMoneyAmount',
          label: '优惠减免总额',
        },
        {
          prop: 'numOfCompletedOrder',
          label: '订单完成数',
        },
      ],
      // 表格接收后台数据
      tableList: { list: [] },
      searchForm: {},
      pageNum: 1,
      pageSize: 50,
      //统计时间
      time: [],
      current: [],
      parkNameList: [],  //停车场下拉框
      //日期快速选择
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick (picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryCurrentDay()
    this.queryTableList()//初始化页面
    this.queryParkNameList()//获取停车场
  },
  //方法集合
  methods: {
    // 导出
    exportFile () {
      let timeMilli = timeDuration(this.time[1], this.time[0])
      // 三天内
      if (timeMilli <= 259200) {
        this.exportFileFunction()
      }
      else {
        this.$message.warning('请导出三天内的数据！')
      }
    },
    exportFileFunction () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$axios.post(this.$downloadBaseUrl + "payment/downloadParkingFeeOut", this.searchForm, {
        responseType: "blob",
        headers: {
          'userToken': localStorage.getItem("userToken")
        },
      }).then(res => {
        let blob = new Blob([res.data], {
          type: "application/vnd.ms-excel",
        }); // 2.获取请求返回的response对象中的blob 设置文件类型，这里以excel为例
        let url = window.URL.createObjectURL(blob); // 3.创建一个临时的url指向blob对象
        // 4.创建url之后可以模拟对此文件对象的一系列操作，例如：预览、下载
        let a = document.createElement("a");
        a.href = url;
        a.download = "缴费记录.xlsx";
        a.click();
        // 5.释放这个临时的对象url
        window.URL.revokeObjectURL(url);
        loading.close()
      }).catch(() => {
        loading.close()
        this.$message.error("导出失败")
      })
    },
    // 点击查看详情
    handleClickDetails (row) {
      this.$router.push({ path: "/parkPayRecordInfo", query: { parkId: row.parkId, queryTime: this.time } })
    },
    // 获取当天时间
    queryCurrentDay () {
      let day = new Date()
      let a = day.getFullYear()
      let b = day.getMonth() + 1
      let c = day.getDate()
      if (JSON.stringify(b).length === 1) {
        b = "0" + b
      }
      if ((JSON.stringify(c).length === 1)) {
        c = "0" + c
      }
      this.current[0] = a + "-" + b + "-" + c + " 00:00:00"
      this.current[1] = a + "-" + b + "-" + c + " 23:59:59"
      if (this.time.length == 0) {
        this.time = this.current
        this.searchForm.orderCreateStartTime = this.current[0]
        this.searchForm.orderCreateEndTime = this.current[1]
      }
    },
    //初始化表格数据
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$parkPayRecord.queryParkingFeeOut(this.searchForm).then(res => {
        res.resultEntity.content.forEach(item => {
          if (item.totalReceivableMoneyAmount) {
            item.totalReceivableMoneyAmount = Number(item.totalReceivableMoneyAmount / 100).toFixed(2)
          }
          else {
            item.totalReceivableMoneyAmount = 0
          }
          if (item.totalReceivedMoneyAmount) {
            item.totalReceivedMoneyAmount = Number(item.totalReceivedMoneyAmount / 100).toFixed(2)
          } else {
            item.totalReceivedMoneyAmount = 0
          }
          if (item.totalRepayMoneyAmount) {
            item.totalRepayMoneyAmount = Number(item.totalRepayMoneyAmount / 100).toFixed(2)
          } else {
            item.totalRepayMoneyAmount = 0
          }
          if (item.totalArrangeMoneyAmount) {
            item.totalArrangeMoneyAmount = Number(item.totalArrangeMoneyAmount / 100).toFixed(2)
          } else {
            item.totalArrangeMoneyAmount = 0
          }
        });
        this.tableList.list = res.resultEntity.content
        this.paginationConfig.total = res.resultEntity.totalElements
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList();
    },
    //顶部查询按钮
    queryButton () {
      this.pageNum = 1;
      if (this.time) {
        this.searchForm.orderCreateStartTime = this.time[0]
        this.searchForm.orderCreateEndTime = this.time[1]
      } else {
        this.searchForm.orderCreateStartTime = this.current[0]
        this.searchForm.orderCreateEndTime = this.current[1]
      }
      this.queryTableList();
    },
    //顶部重置按钮
    resetForm () {
      this.searchForm = {}
      this.searchForm.orderCreateStartTime = this.current[0]
      this.searchForm.orderCreateEndTime = this.current[1]
      this.time = [this.current[0], this.current[1]]
      this.queryTableList()
    },
    //获取停车场名称
    queryParkNameList () {
      let info = {
        columnName: ['park_id', 'park_name'],
        tableName: 'tb_park',
        whereStr: [
          {
            "colName": "park_type_code",
            "value": "2"
          }
        ]
      }
      this.$queryDict.queryDictWithAuth(info).then((response) => {
        this.parkNameList = response.resultEntity
      })
    },
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 130px;
//@import url(); 引入公共css类
.mainbody {
  height: 100%;

  // 顶部查询条件
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    // height: calc(100% - #{$topSelectHeight});
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
      .tableTitleButton {
        float: right;
      }
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.search_column1 {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
}
.search_column2 {
  margin-top: 20px;
}
</style>

